import React, { useState }from "react";
import CWApp from "../assets/img/webappDev/cwapp.png";
import UIUX from "../assets/img/webappDev/uiux.png";
import OpenSDev from "../assets/img/webappDev/opensdev.png";
import QA from "../assets/img/webappDev/qa.png";
import FrontDev from "../assets/img/webappDev/frontdev.png";
import BackDev from "../assets/img/webappDev/backdev.png";
import ScrollToTop from "../components/ScrollToTop";


function WebAppService(){
    const [activeSection, setActiveSection] = useState('Enhanced User Experience');
    const handleTagClick = (section) => {
      setActiveSection(section);
    };
    
    return(
      <>
        <div className="no-context-menu no-select">
        {/* <!-- Page Title -->  */}
            <div className="page-title dark-background"> 
            <div className="heading"> 
                <div className="container"> 
                <div className="row d-flex justify-content-center text-center"> 
                    <div className="col-lg-8"> 
                    <h1>Web Application Development</h1> 
                    <p className="mb-0 b-text">Craft Dynamic Web Experiences: Dive into the World of Web Application Development.</p> 
                    </div> 
                </div> 
                </div>
            </div> 
            
            <nav className="breadcrumbs"> 
                <div className="container"> 
                <ol> 
                    <li><a href="/">Home</a></li> 
                    <li className="current">Web Application Development</li> 
                </ol> 
                </div> 
            </nav> 
            </div>
        {/* <!-- End Page Title -->  */}

        {/* <!-- Services Section -->  */}
            <section id="services" className="services section"> 
            {/* <!-- Section Title -->  */}
            <div className="container section-title" data-aos="fade-up"> 
            <h2>Web Application Development Services</h2> 
            </div>
            {/* <!-- End Section Title -->  */}

            <div className="container"> 
            <div className="row gy-4"> 
            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100"> 
                <div className="service-item position-relative"> 
                <div className="row g-0"> 
                    <div className="col-md-4 container d-flex align-items-center justify-content-center"> 
                    <div className="icon"> 
                        <img src={CWApp} alt=""/>
                        {/* <i className="bi bi-phone"></i>  */}
                    </div> 
                    </div> 

                    <div className="col-md-8"> 
                    <div className="card-body"> 
                        <h3>Custom Web App Development</h3> 
                        <p className="card-text">From idea to impact, we create custom web apps that optimize your operations and drive results.</p>
                    </div>
                    </div> 
                </div> 
                </div>
                {/* <!-- End Service Item -->  */}
            </div> 

            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100"> 
                <div className="service-item position-relative"> 
                <div className="row g-0"> 
                    <div className="col-md-4 container d-flex align-items-center justify-content-center"> 
                    <div className="icon"> 
                        <img src={UIUX} alt=""/>
                        {/* <i className="bi bi-phone"></i>  */}
                    </div> 
                    </div>

                    <div className="col-md-8"> 
                    <div className="card-body"> 
                        <h3>UI/UX Design</h3> 
                        <p className="card-text">From wireframes to prototypes. Our UI/UX experts deliver designs that captivate and convert.</p> 
                    </div> 
                    </div> 
                </div> 
                </div>
                {/* <!-- End Service Item -->  */}

            </div> 



            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100"> 
                <div className="service-item position-relative"> 

                <div className="row g-0"> 
                    <div className="col-md-4 container d-flex align-items-center justify-content-center"> 
                    <div className="icon"> 
                        <img src={OpenSDev} alt=""/>
                        {/* <i className="bi bi-phone"></i>  */}
                    </div> 
                    </div> 

                    <div className="col-md-8"> 
                    <div className="card-body"> 
                        <h3>Open Source Development Services</h3> 
                        <p className="card-text">Contributing to open-source communities, we specialize in development and engagement.</p> 
                        {/* <!-- <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p> -->  */}
                    </div> 
                    </div> 
                </div> 
                </div>
                {/* <!-- End Service Item -->  */}
            </div> 



            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100"> 
                <div className="service-item position-relative"> 
                <div className="row g-0"> 
                    <div className="col-md-4 container d-flex align-items-center justify-content-center"> 
                    <div className="icon"> 
                    <img src={QA} alt=""/>
                        {/* <i className="bi bi-phone"></i>  */}
                    </div> 
                    </div> 

                    <div className="col-md-8">
                    <div className="card-body"> 
                        <h3>Quality Assurance</h3> 
                        <p className="card-text">A commitment to excellence. Our QA experts guarantee a bug-free and reliable product.</p> 
                    </div> 
                    </div> 
                </div> 
                </div>
                {/* <!-- End Service Item -->  */}
            </div> 



            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100"> 

                <div className="service-item position-relative"> 
                <div className="row g-0"> 
                    <div className="col-md-4 container d-flex align-items-center justify-content-center"> 
                    <div className="icon"> 
                    <img src={FrontDev} alt=""/>
                        {/* <i className="bi bi-phone"></i>  */}
                    </div> 
                    </div> 

                    <div className="col-md-8">
                    <div className="card-body">
                        <h3>Frontend Development Services</h3> 
                        <p className="card-text">Crafting visually stunning and responsive user interfaces. We bring your designs to life.</p> 
                    </div> 
                    </div> 
                </div> 
                </div>
                {/* <!-- End Service Item -->  */}

            </div> 



            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100"> 

                <div className="service-item position-relative"> 
                <div className="row g-0"> 
                    <div className="col-md-4 container d-flex align-items-center justify-content-center"> 
                    <div className="icon"> 
                    <img src={BackDev} alt=""/>
                        {/* <i className="bi bi-phone"></i>  */}
                    </div> 
                    </div> 

                    <div className="col-md-8"> 
                    <div className="card-body"> 
                        <h3>Backend Development Services</h3> 
                        <p className="card-text">From APIs to databases, we create robust and efficient backend infrastructure.</p> 
                    </div> 
                    </div> 
                </div> 
                </div>
                {/* <!-- End Service Item -->  */}
            </div> 
            </div> 
            </div> 
            </section> 
        {/* <!-- /Services Section -->  */}

        {/* <!-- Service Details Section -->  */}
        <section className="stats section">
                    <div className="container" >
                        <div className="row gy-4 align-items-center">
                            <div className="col-lg-5 section-title" style={{ paddingBottom: '0%' }}>
                                <h2>Web Application Development Benefits<br /></h2>
                            </div>
                            <div className="col-lg-7">
                                <div className="row gy-4">
                                    <p>Anantam delivers exceptional web application development services tailored to the unique needs of leading enterprises, SMEs, and startups. Our team of skilled web developers crafts intuitive and user-friendly interfaces that maximize your conversion rates. We prioritize security and privacy, safeguarding your sensitive data through robust measures and avoiding the risks associated with remote storage. Our web applications are designed to scale seamlessly, enabling you to stay ahead of the competition. We ensure accessibility from any browser and device, providing a 24/7 user experience.</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <section id="service-details" className="service-details section"> 
                <div className="container"> 
                    <div className="row gy-4"> 
                        <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100"> 
                            <div className="services-list"> 
                            <a  className={activeSection === 'Enhanced User Experience' ? 'active' : ''} style={{ cursor: 'pointer' }} 
                                onClick={() => handleTagClick('Enhanced User Experience')}>Enhanced User Experience</a> 
                            <a className={activeSection === 'Increased Efficiency' ? 'active' : ''} style={{ cursor: 'pointer' }} 
                                onClick={() => handleTagClick('Increased Efficiency')}>Increased Efficiency</a> 
                            <a className={activeSection === 'Improved Productivity' ? 'active' : ''} style={{ cursor: 'pointer' }} 
                                onClick={() => handleTagClick('Improved Productivity')}>Improved Productivity</a> 
                            <a className={activeSection === 'Enhanced Security' ? 'active' : ''} style={{ cursor: 'pointer' }} 
                                onClick={() => handleTagClick('Enhanced Security')}>Enhanced Security</a> 
                            <a className={activeSection === 'Scalability and Flexibility' ? 'active' : ''} style={{ cursor: 'pointer' }} 
                                onClick={() => handleTagClick('Scalability and Flexibility')}>Scalability and Flexibility</a> 
                            </div> 
                        </div> 

                    <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
                        <h3>{activeSection}</h3>
                        <p>{activeSection === 'Enhanced User Experience' && 'In today\'s fast-paced digital world, user experience is paramount. Our custom web applications are designed to deliver intuitive, engaging, and seamless interactions that leave a lasting impression. By prioritizing user-centric design principles, we ensure that your website or application is not only visually appealing but also easy to navigate and use. Our team meticulously crafts interfaces that cater to your target audience\'s needs and preferences, fostering a positive and memorable experience.'}</p>
                        <p>{activeSection === 'Increased Efficiency' && 'Our custom web applications are designed to optimize your workflows and boost productivity. By automating repetitive tasks and streamlining processes, we help you save time and resources. Our solutions are tailored to your specific business needs, ensuring that your operations run smoothly and efficiently. Experience the benefits of increased efficiency and unlock your business\'s full potential.'}</p>
                        <p>{activeSection === 'Improved Productivity' && 'Our custom web applications are designed to empower your team and enhance productivity. By streamlining workflows, automating repetitive tasks, and providing easy access to essential tools and information, we help you maximize efficiency and achieve your goals. Our solutions are tailored to your specific business needs, ensuring that your team can focus on high-value activities and drive results.'}</p>
                        <p>{activeSection === 'Enhanced Security' && 'In today\'s digital age, data security is a top priority. Our custom web applications are built with robust security measures to safeguard your sensitive information. We employ industry-leading practices to protect your data from unauthorized access, breaches, and other threats. Our solutions are designed to comply with relevant security standards and regulations, ensuring that your data is always protected.'}</p>
                        <p>{activeSection === 'Scalability and Flexibility' && 'Our custom web applications are designed to grow with your business. Whether you\'re experiencing rapid growth or need to adapt to changing market conditions, our solutions can easily scale to meet your evolving needs. Our flexible architecture allows us to accommodate future changes and ensure that your application remains efficient and effective.'}</p>
                        <ul>
                        <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Enhanced User Experience' ? 'Increased customer satisfaction' : activeSection === 'Increased Efficiency' ? 'Reduced manual labor' : activeSection === 'Improved Productivity' ? 'Faster turnaround times' : activeSection === 'Enhanced Security' ? 'Data protection' : activeSection === 'Scalability and Flexibility' ? 'Future-proofing' : ''}</span></li>
                        <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Enhanced User Experience' ? 'Improved conversion rates' : activeSection === 'Increased Efficiency' ? 'Enhanced productivity' : activeSection === 'Improved Productivity' ? 'Reduced errors' : activeSection === 'Enhanced Security' ? 'Customer trust' : activeSection === 'Scalability and Flexibility' ? 'Flexibility' : ''}</span></li>
                        <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Enhanced User Experience' ? 'Higher user engagement' : activeSection === 'Increased Efficiency' ? 'Scalability' : activeSection === 'Improved Productivity' ? 'Increased employee satisfaction' : activeSection === 'Enhanced Security' ? 'Peace of mind' : activeSection === 'Scalability and Flexibility' ? 'Cost-effectiveness' : ''}</span></li>
                        </ul>
                    </div> 
                    </div> 
                </div> 
            </section>

            {/* web application development Teck track  */}
             <section className="section ">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Our Web Application Development Tech Stack<br /></h2>
                </div>
                <div className="container ">
                    <div style={{ display: 'flex' }}>
                        <div className="col-sm-4 ais-ts-head">
                            <span className="ais-ts-text">Front-End</span>
                        </div>
                        <div className="col-sm-8 ais-ts-data ">
                            <div className="ais-ts-text">
                                <ul>
                                    <li> <i className="bi bi-check-circle-fill"style={{marginRight:10}} ></i>React JS</li>
                                    <li> <i className="bi bi-check-circle-fill"style={{marginRight:10}} ></i>VueJS</li>
                                    <li> <i className="bi bi-check-circle-fill"style={{marginRight:10}} ></i>Angular JS</li>
                                    <li> <i className="bi bi-check-circle-fill"style={{marginRight:10}} ></i>Javascript</li>  
                                    <li> <i className="bi bi-check-circle-fill"style={{marginRight:10}} ></i>PHP</li>    
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <div className="col-sm-4 ais-ts-head">
                            <span className="ais-ts-text">Back-End</span>
                        </div>
                        <div className="col-sm-8 ais-ts-data ">
                            <div className="ais-ts-text">
                                <ul>
                                    <li> <i className="bi bi-check-circle-fill" style={{marginRight:10}} ></i>Node js</li>
                                    <li> <i className="bi bi-check-circle-fill" style={{marginRight:10}} ></i>Spring Boot</li>  
                                    <li> <i className="bi bi-check-circle-fill" style={{marginRight:10}} ></i>Python</li>
                                    <li> <i className="bi bi-check-circle-fill" style={{marginRight:10}} ></i>Laravel</li>
                                    <li> <i className="bi bi-check-circle-fill" style={{marginRight:10}} ></i>Django</li>  
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <div className="col-sm-4 ais-ts-head">
                            <span className="ais-ts-text">Database</span>
                        </div>
                        <div className="col-sm-8 ais-ts-data ">
                            <div className="ais-ts-text">
                                <ul>
                                    <li> <i className="bi bi-check-circle-fill"style={{marginRight:10}} ></i>MS SQL</li>
                                    <li> <i className="bi bi-check-circle-fill"style={{marginRight:10}} ></i>MySQL</li>
                                    <li> <i className="bi bi-check-circle-fill"style={{marginRight:10}} ></i>SQLite</li>   
                                    <li> <i className="bi bi-check-circle-fill"style={{marginRight:10}} ></i>MongoDB</li>  
                                    <li> <i className="bi bi-check-circle-fill"style={{marginRight:10}} ></i>PostgreSQL</li>    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <ScrollToTop/>
      </>
    );
}

export default WebAppService;