import React from "react";
import './assets/css/main.css'
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import ServicesDetail from "./pages/ServicesDetail";
import WebAppService from "./pages/WebAppService";
import SoftwareProductEng from "./pages/SoftwareProductEng";
import UiUxService from "./pages/UiUxService";
import SEODigitalMarketing from "./pages/SEODigitalMarketing";
import QualityAnalysisTesting from "./pages/QualityAnalysisTesting";
import Preloader from "./components/Preloader";
import PortfolioDetails from "./pages/PortfolioDetails";
import NictPortfolio from "./pages/NictPortfolio";
import SanaviPortfolio from "./pages/SanaviPortfolio";
import HireDeveloper from "./pages/HireDeveloper";

function App() {
  return (
    
    <Router>
      <Preloader/>
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/service-detail" element={<ServicesDetail/>}/>
          <Route path="/contactus" element={<ContactUs/>}/>
          <Route path="/web-app-service" element={<WebAppService/>}/>
          <Route path="/software-prod-eng" element={<SoftwareProductEng/>}/>
          <Route path="/ui-ux-service" element={<UiUxService/>}/>
          <Route path="/seo-digitalmarketing" element={<SEODigitalMarketing/>}/>
          <Route path="/quality-analysis-testing" element={<QualityAnalysisTesting/>}/>
          <Route path="/portfolio-details" element={<PortfolioDetails/>}/>
          <Route path="/nict-details" element={<NictPortfolio/>}/>
          <Route path="/sanavi-details" element={<SanaviPortfolio/>}/>
          <Route path="/hire-developer" element={<HireDeveloper/>}/>
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
