import React, { useState } from "react";
import marketing from "../assets/img/marketing.png";
import searchengine from "../assets/img/search-engine.png"
import contentwriter from "../assets/img/content-writer.png";
import websiteanalytics from "../assets/img/website-analytics.png";
import ScrollToTop from "../components/ScrollToTop";


function SEODigitalMarketing() {
    const [activeSection, setActiveSection] = useState('Increased Visibility');
    const handleTagClick = (section) => {
        setActiveSection(section);
    };

    return (
        <>
            <div className="no-context-menu no-select">
                {/* <!-- Page Title -->  */}
                <div className="page-title dark-background">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>SEO & Digital Marketing</h1>
                                    <p className="mb-0 b-text">Innovative UI/UX Solutions That Drive Growth.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="/">Home</a></li>
                                <li className="current">SEO & Digital Marketing</li>
                            </ol>
                        </div>
                    </nav>
                </div>
                {/* <!-- End Page Title -->  */}

                {/* <!-- Services Section -->  */}
                <section id="services" className="services section">
                    {/* <!-- Section Title -->  */}
                    <div className="container section-title" data-aos="fade-up">
                        <h2>SEO & Digital Marketing Services</h2>
                    </div>
                    {/* <!-- End Section Title -->  */}

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={searchengine} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Search Engine Marketing</h3>
                                                <p className="card-text">Anantam delivers results-driven SEM campaigns. We optimize your website's visibility and attract targeted traffic that converts.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={marketing} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Social Media Marketing</h3>
                                                <p className="card-text">Anantam's Social Media Marketing services elevate your brand's online presence. We create engaging content, build loyal communities, and drive meaningful interactions across popular platforms.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}

                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">

                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={contentwriter} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Content Marketing</h3>
                                                <p className="card-text">Anantam creates compelling content that engages your audience and drives results. Our expertise in content strategy, creation, and distribution ensures your brand's message resonates.</p>
                                                {/* <!-- <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p> -->  */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>


                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={websiteanalytics} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Website Analytics</h3>
                                                <p className="card-text">Anantam offers comprehensive website analytics services. We track key metrics, analyze user behavior, and provide actionable insights to optimize your online presence.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- /Services Section -->  */}

                {/* <!-- Service Details Section -->  */}
                <section className="stats section">
                    <div className="container" >
                        <div className="row gy-4 align-items-center">

                            <div className="col-lg-5 section-title" style={{ paddingBottom: '0%' }}>
                                <h2>SEO & Digital Marketing Benefits<br /></h2>
                            </div>

                            <div className="col-lg-7">

                                <div className="row gy-4">
                                   <p> SEO and digital marketing offer numerous benefits for businesses. By optimizing your website for search engines and implementing effective digital marketing strategies, you can increase your online visibility, reach your target audience more effectively, measure your campaign performance, and improve your overall online presence. These benefits can lead to increased website traffic, leads, conversions, and brand awareness, ultimately helping your business grow and succeed in the competitive digital landscape.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="service-details" className="service-details section">
                    {/* <!-- Section Title -->  */}
                    <div id="user-experience" className="container section-title" data-aos="fade-up">
                        <h2>SEO & Digital Marketing Benefits</h2>
                    </div>
                    {/* <!-- End Section Title -->  */}
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="services-list">
                                    <a className={activeSection === 'Increased Visibility' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Increased Visibility')}>Increased Visibility</a>
                                    <a className={activeSection === 'Targeted Reach' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Targeted Reach')}>Targeted Reach</a>
                                    <a className={activeSection === 'Measurable Results' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Measurable Results')}>Measurable Results</a>
                                    <a className={activeSection === 'Cost-Effective' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Cost-Effective')}>Cost-Effective</a>
                                    <a className={activeSection === 'Brand Awareness' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Brand Awareness')}>Brand Awareness</a>
                                </div>
                            </div>

                            <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
                                <h3>{activeSection}</h3>
                                <p>{activeSection === 'Increased Visibility' && 'Increased Visibility in the context of SEO and digital marketing refers to the higher ranking of your website in search engine results pages (SERPs). When your website appears closer to the top of search results for relevant keywords, it is more likely to be seen by potential customers. This increased visibility can lead to more website traffic, leads, and conversions.'}</p>
                                <p>{activeSection === 'Targeted Reach' && 'Targeted Reach in SEO and digital marketing refers to the ability to deliver your marketing messages to a specific audience rather than a broad, general audience. This is achieved by using various techniques.'}</p>
                                <p>{activeSection === 'Measurable Results' && 'Measurable Results in digital marketing refers to the ability to track and quantify the performance of your campaigns. Unlike traditional marketing methods, digital marketing offers a wealth of data and analytics that can be used to measure key metrics.'}</p>
                                <p>{activeSection === 'Cost-Effective' && 'Cost-Effective in the context of digital marketing and SEO means that these strategies can deliver a high return on investment (ROI) compared to traditional marketing methods. While there are costs associated with digital marketing, such as advertising fees and the time and resources required for SEO, the potential benefits, like increased website traffic and conversions, can often outweigh the costs.'}</p>
                                <p>{activeSection === 'Brand Awareness' && 'Brand Awareness refers to the extent to which people recognize and remember your brand. It\'s about creating a lasting impression in the minds of your target audience. When a brand is well-known and recognized, it can influence consumer choices and build trust.'}</p>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Increased Visibility' ? 'higher in search engine' : activeSection === 'Targeted Reach' ? 'Clear and Consistent Layout' : activeSection === 'Measurable Results' ? 'Website traffic' : activeSection === 'Cost-Effective' ? 'Targeted Reach' : activeSection === 'Brand Awareness' ? 'Brand Recognition' : ''}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Increased Visibility' ? 'Potential customers' : activeSection === 'Targeted Reach' ? 'Consistent Branding' : activeSection === 'Measurable Results' ? 'Lead generation' : activeSection === 'Cost-Effective' ? 'Measurable Results' : activeSection === 'Brand Awareness' ? 'Brand Equity' : ''}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Increased Visibility' ? 'attracting traffic' : activeSection === 'Targeted Reach' ? 'Accessibility' : activeSection === 'Measurable Results' ? 'Conversion rates' : activeSection === 'Cost-Effective' ? 'Global Reach' : activeSection === 'Brand Awareness' ? 'Brand Recall' : ''}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                {/* web application development Teck track  */}
                <section className="section ">
                    <div className="container section-title" data-aos="fade-up">
                    <h2>Our SEO and Digital Marketing Tech Stack<br /></h2>
                    </div>
                    <div className="container ">
                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">SEO Tools</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Google Search Console</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Google Analytics</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Ahrefs</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>SEMrush</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Digital Marketing Tools</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Google Ads</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Facebook Ads Manager</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>LinkedIn Campaign Manager</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Social Media Management Tools</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Hootsuite</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Buffer</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Sprout Social</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ScrollToTop/>
        </>
    );
}

export default SEODigitalMarketing;