import React, { useRef, useEffect } from 'react';
import AOS from 'aos'; // Assuming you've installed AOS

function ScrollToTop() {
  const scrollTopRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        scrollTopRef.current.classList.add('active');
      } else {
        scrollTopRef.current.classList.remove('active');
      }
    };

    // Initialize AOS animation
    AOS.init({
      duration: 600,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center" ref={scrollTopRef} onClick={handleClick}>
      <i className="bi bi-arrow-up-short"></i>
    </a>
  );
}

export default ScrollToTop;