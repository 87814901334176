import React from "react";
import HireDevelopers from "../assets/img/hire.png";

function HireDedicatedSection(){
return(
    <section id="hireDeveloper" className="accent-background section">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Hire Developers<br/></h2>
        {/* <!-- <p>A Team Dedicated to Your Success</p> --> */}
      </div>  {/* <!-- End Section Title --> */}
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            {/* <!-- <h3>At Anantam IT Solutions, we are dedicated to delivering value and excellence, and we strive to build lasting partnerships with our clients based on trust and mutual success.</h3> --> */}
            <h3 className="py-4">Ready to build your software? Your dedicated team is waiting !</h3>
            {/* <!-- <ul> --> */}
            <h6 className="py-2"> <i className="bi bi-check-circle-fill ais-colour"></i> <span> Unmatched Expertise at Your
                Command</span></h6>
            <h6 className="py-2"> <i className="bi bi-check-circle-fill ais-colour"></i> <span> Dedicated Focus on Your
                Project</span></h6>
            <h6 className="py-2"> <i className="bi bi-check-circle-fill ais-colour"></i> <span>Cost-Effective and Scalable
                Solutions</span></h6>
            <h6 className="py-2"> <i className="bi bi-check-circle-fill ais-colour"></i> <span>Guaranteed Quality and Timely
                Delivery</span></h6>
            <h6 className="py-2"> <i className="bi bi-check-circle-fill ais-colour"></i> <span>Seamless Communication and
                Collaboration</span></h6>
            <h6 className="py-2"> <i className="bi bi-check-circle-fill ais-colour"></i> <span> A Trusted Partner for Long-Term
                Success</span></h6>
            {/* <!-- </ul> --> */}
            <div className="d-flex justify-content-center py-5">
              <h5><a href="#contact" className="ais-btn">Let’s connect</a></h5>
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="250">
            <div className="content ps-0 ps-lg-5">
              <div className="position-relative mt-4">
                <img src={HireDevelopers} className="img-fluid rounded-4" alt="HireDeveloper"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> 
);
}
export default HireDedicatedSection;