import React from "react"
import { Link } from "react-router-dom";

function ServicesSection(){
    const handleClick = () => {
        window.scrollTo(0, 0);
      };
    return(
        <section id="services" className="services section">
        {/* <!-- Section Title --> */} 
        <div className="container section-title" data-aos="fade-up">
        <h2>Our Services</h2>
        <p>Launch your project into the infinite expanse of success, where it will reach new galaxies of achievement.
        </p>
        </div>
        {/* <!-- End Section Title --> */}
        <div className="container">
        <div className="row gy-4">
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div className="service-item  position-relative">
            <div className="icon">
                <i className="bi bi-terminal"></i>
            </div>
            <h3>Web App Development</h3>
            <p>Build powerful, custom web apps that drive your business. Our team delivers high-performance solutions
                tailored to your unique needs, from concept to deployment. </p>
            <Link to="web-app-service" onClick={handleClick}><a className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></a> </Link>
            </div>
        </div>
        {/* <!-- End Service Item --> */}
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div className="service-item position-relative">
            <div className="icon">
                <i className="bi bi-phone"></i>
            </div>
            <h3>Mobile Application Development </h3>
            <p>Create engaging mobile apps that drive growth. Our team delivers innovative solutions tailored to your
                unique needs, from strategy to development. </p>
                <Link to="service-detail"onClick={handleClick}> <a className="readmore stretched-link">Read more <i
                className="bi bi-arrow-right"></i></a></Link>
            </div>
        </div>
        {/* <!-- End Service Item --> */}
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div className="service-item position-relative">
            <div className="icon">
                <i className="bi bi-code-square"></i>
            </div>
            <h3>Software Product Engineering</h3>
            <p>Transform your ideas into powerful software. Our team delivers end-to-end solutions that drive your
                business forward. </p>
                <Link to="software-prod-eng" onClick={handleClick}><a className="readmore stretched-link">Read more <i
                className="bi bi-arrow-right"></i></a></Link>
            </div>
        </div>
        {/* <!-- End Service Item --> */}
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
            <div className="service-item position-relative">
            <div className="icon">
                <i className="bi bi-ui-checks-grid"></i>
            </div>
            <h3>UI/UX & Graphic designing </h3>
            <p>Our UI/UX and graphic design team crafts visually stunning, user-friendly designs that elevate your
                brand and drive engagement. </p>
                <Link to="/ui-ux-service" onClick={handleClick}><a className="readmore stretched-link">Read more <i
                className="bi bi-arrow-right"></i></a></Link>
            </div>
        </div>
        {/* <!-- End Service Item --> */}
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
            <div className="service-item position-relative">
            <div className="icon">
                <i className="bi bi-broadcast"></i>
            </div>
            <h3>SEO & Digital Marketing</h3>
            <p>Boost your online success with our seo & digital marketing expertise. We optimize your online presence
                to reach your target audience, build brand awareness, and drive results.</p>
                <Link to="seo-digitalmarketing"onClick={handleClick}><a className="readmore stretched-link">Read more <i
                className="bi bi-arrow-right"></i></a></Link>
            </div>
        </div>
        {/* <!-- End Service Item --> */}
        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
            <div className="service-item position-relative">
            <div className="icon">
                <i className="bi bi-check2-square"></i>
            </div>
            <h3>Quality Analysis & Testing </h3>
            <p>Ensure product excellence with our QA expertise. Our team delivers high-quality, bug-free applications
                through rigorous testing. </p>
                <Link to="quality-analysis-testing" onClick={handleClick}><a className="readmore stretched-link">Read more <i
                className="bi bi-arrow-right"></i></a></Link>
            </div>
        </div> 
        {/* <!-- End Service Item --> */}
        </div>
        </div>
        </section>

    );
}
export default ServicesSection;