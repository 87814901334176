import React, { useEffect } from "react";
import stats from "../assets/img/stats.png";
import purecounter from "@srexi/purecounterjs";

function StatsSection() {
    React.useEffect(() => {
        // Initialize PureCounter instances
        const counters = document.querySelectorAll('.purecounter');
        counters.forEach((counter) => {
            new purecounter(counter);
        });
    }, []);
    return (
        <>
            <section className="stats section ">
                <div className="container" >
                    <div className="row gy-4 align-items-center">

                        <div className="col-lg-5 section-title" style={{ paddingBottom: '0%' }}>
                            <h2> Your Success, Our Priority<br /></h2>
                        </div>

                        <div className="col-lg-7">

                            <div className="row gy-4">

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-arrow-up-right-square flex-shrink-0"></i>
                                        <div>
                                            <h4><span><strong>Ultimate Achievement </strong></span></h4>
                                            <p><strong>At Anantam, we're committed to delivering exceptional outcomes that align perfectly with your unique needs.</strong> </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-easel flex-shrink-0"></i>
                                        <div>
                                            <h4><span><strong>Graceful Design </strong></span></h4>
                                            <p><strong>Experience the art of design. Our experts create elegant websites that leave a lasting impression.</strong> </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-bounding-box-circles flex-shrink-0"></i>
                                        <div>
                                            <h4><span><strong>Creative Solutions </strong></span></h4>
                                            <p><strong> your ideas with our creative spark. Anantam is your partner for innovative software solutions. </strong> </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-key flex-shrink-0"></i>
                                        <div>
                                            <h4><span><strong>The Key to Success  </strong></span></h4>
                                            <p><strong>Don't just take our word for it. Our impressive track record and positive reviews speak volumes about our quality of service.</strong>  </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="stats" className="stats section ais-background">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Your Trusted Global Software Partner<br /></h2>
                </div>
                <div className="container" data-aos="fade-up" data-aos-delay="100">

                    <div className="row gy-4 align-items-center">

                        <div className="col-lg-5">
                            <img src={stats} alt="" className="img-fluid" />
                        </div>

                        <div className="col-lg-7">

                            <div className="row gy-4">

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-emoji-smile flex-shrink-0"></i>
                                        <div>
                                            <span data-purecounter-start="0" data-purecounter-end="10" data-purecounter-duration="1" className="purecounter"></span>
                                            <p><strong>Happy Clients</strong> <span></span></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-journal-richtext flex-shrink-0"></i>
                                        <div>
                                            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                <span data-purecounter-start="0" data-purecounter-end="25" className="purecounter">0</span>
                                                <span>+</span>
                                            </div>
                                            <p><strong>Web and Mobile app</strong> <span>designed and developed</span></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-headset flex-shrink-0"></i>
                                        <div>
                                            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                <span data-purecounter-start="0" data-purecounter-end="600" className="purecounter">0</span>
                                                <span>+</span>
                                            </div>
                                            <p><strong>Hours Of Support</strong> <span></span></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-people flex-shrink-0"></i>
                                        <div>
                                            <span data-purecounter-start="0" data-purecounter-end="18" data-purecounter-duration="1"
                                                className="purecounter"></span>
                                            <p><strong>Engineers working at Anantam </strong> <span></span></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                            </div>

                        </div>

                    </div>

                </div>

            </section>
            {/* <!-- /Stats Section --> */}

        </>
    );
}
export default StatsSection;