import React, { useState, useEffect } from 'react';

function HireDeveloper() {
    // const [data, setData] = useState(null);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const response = await fetch('/data.json');
    //         const jsonData = await response.json();
    //         setData(jsonData);
    //         console.log(jsonData,"data");
            

    //     };

    //     fetchData();
    // }, []);
    return (
        <>
            {/* <!-- Page Title -->  */}
            <div className="page-title">
                <div className="heading">
                    <div className="container">
                        <div className="row d-flex justify-content-center text-center">
                            <div className="col-lg-8">
                                <h1> Hire Next.js Developers</h1>
                                <p className="mb-0">Enhance business performance and accelerate growth with our next-level Next.js development services.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li className="current">Portfolio Details</li>
                        </ol>
                    </div>
                </nav>
            </div>
            {/* <!-- End Page Title --> */}
            {/* <div>
                {data
                    ? (
                        <div>
                            <h1>{data.title}</h1>
                            <p>{data.description}</p>
                            <ul>
                                {data.items.map((item) => (
                                    <li key={item.id}>{item.name} hi this is data</li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div>
                        <p>Loading data...</p>
                        <h1>{data.title}</h1>
                            <p>{data.description}</p>
                            <ul>
                                {data.items.map((item) => (
                                    <li key={item.id}>{item.name} hi this is data</li>
                                ))}
                            </ul>
                        </div>
                    )}
            </div> */}
        </>
    );
}
export default HireDeveloper;