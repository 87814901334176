import React, { useState } from "react";
import androidappDevelopment from "../assets/img/mobileappDev/androidappDevelopment.png";
import hybridtechMobile from "../assets/img/mobileappDev/hybridtechMobile.png";
import iosappDevelopment from "../assets/img/mobileappDev/iosappDevelopment.png";
import mobileappTechAdvice from "../assets/img/mobileappDev/mobileappTechAdvice.png";
import techTrasformation from "../assets/img/mobileappDev/techTrasformation.png";
import upgradationMigration from "../assets/img/mobileappDev/upgradationMigration.png";
import ScrollToTop from "../components/ScrollToTop";


function ServicesDetail() {
    const [activeSection, setActiveSection] = useState('Enhancing Efficiency');
    const handleTagClick = (section) => {
        setActiveSection(section);
    };

    return (
        <>
            <div className="no-context-menu no-select">
                {/* <!-- Page Title -->  */}
                <div className="page-title dark-background">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>Mobile Application Development</h1>
                                    <p className="mb-0 b-text">Mobile App Development: Building the Future</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="/">Home</a></li>
                                <li className="current">Mobile App Development</li>
                            </ol>
                        </div>
                    </nav>
                </div>
                {/* <!-- End Page Title -->  */}

                {/* <!-- Services Section -->  */}
                <section id="services" className="services section">
                    {/* <!-- Section Title -->  */}
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Mobile App Development Services</h2>
                    </div>
                    {/* <!-- End Section Title -->  */}
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative hover-hand">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={androidappDevelopment} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Android App Development</h3>
                                                <p className="card-text">Our Android app development experts create robust and scalable applications that cater to the vast Android user base.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative hover-hand">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={iosappDevelopment} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>iOS App Development</h3>
                                                <p className="card-text">We build native iOS apps that leverage the power of the Apple ecosystem, offering a seamless user experience on iPhones and iPads.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}

                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative hover-hand ">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={hybridtechMobile} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Hybrid Tech for Mobile</h3>
                                                <p className="card-text">For businesses seeking a cost-effective and time-efficient solution, we offer hybrid app development that combines the best of both native and web technologies.</p>
                                                {/* <!-- <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p> -->  */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative hover-hand">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={techTrasformation} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Tech Transformation</h3>
                                                <p className="card-text">We help businesses embrace digital innovation and transform their operations through cutting-edge mobile solutions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-item position-relative hover-hand">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={upgradationMigration} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Upgradation & Migration</h3>
                                                <p className="card-text">Our team can modernize your existing mobile apps, ensuring they remain compatible with the latest technologies and platforms.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-item position-relative hover-hand">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={mobileappTechAdvice} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Mobile App Tech Advisors</h3>
                                                <p className="card-text">Our expert technology consultants will help you choose the best tech stack for your mobile app, ensuring a successful development process.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- /Services Section -->  */}

                {/* <!-- Service Details Section -->  */}
                <section className="stats section">
                    <div className="container" >
                        <div className="row gy-4 align-items-center">
                            <div className="col-lg-5 section-title" style={{ paddingBottom: '0%' }}>
                                <h2>Mobile Application Development Benefits<br /></h2>
                            </div>
                            <div className="col-lg-7">
                                <div className="row gy-4">
                                    <p>At Anantam, we're dedicated to crafting mobile applications that captivate your audience and drive your business forward. Our team of skilled developers specializes in creating high-performance, secure, and user-friendly apps for both iOS and Android platforms.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="service-details" className="service-details section">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="services-list">
                                    <a className={activeSection === 'Enhancing Efficiency' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Enhancing Efficiency')}>Enhancing Efficiency</a>
                                    <a className={activeSection === 'Protecting Your Mobile App' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Protecting Your Mobile App')}>Protecting Your Mobile App</a>
                                    <a className={activeSection === 'High Scalability' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('High Scalability')}>High Scalability</a>
                                    <a className={activeSection === 'Cross-Platform Development' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Cross-Platform Development')}>Cross-Platform Development</a>
                                    <a className={activeSection === 'Dedicated App Developers' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Dedicated App Developers')}>Dedicated App Developers</a>
                                </div>
                            </div>

                            <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
                                <h3>{activeSection}</h3>
                                <p>{activeSection === 'Enhancing Efficiency' && 'At Anantam, we specialize in developing mobile apps that enhance efficiency and streamline operations. Our team of skilled developers leverages the latest technologies and industry best practices to create customized solutions that optimize your workflows and improve productivity. From automation and real-time data access to streamlined processes and intuitive interfaces, our mobile apps empower businesses and individuals to achieve greater efficiency and achieve their goals. Experience the transformative power of Anantam\'s mobile app development services and unlock new opportunities for growth and success.'}</p>
                                <p>{activeSection === 'Protecting Your Mobile App' && 'Anantam offers a comprehensive mobile app development and protection solution. Our services encompass both the creation of secure and innovative mobile apps as well as the implementation of robust security measures to safeguard your app from potential threats. Our team of experts specializes in developing apps that adhere to the highest security standards, incorporating features such as malware detection, data encryption, network security, code obfuscation, and regular updates. By partnering with Anantam, you can benefit from a one-stop solution for all your mobile app development and protection needs, ensuring the security and success of your digital endeavors. '}</p>
                                <p>{activeSection === 'High Scalability' && 'Our team specializes in developing highly scalable mobile apps that can effortlessly handle increasing user loads and data volumes. We leverage robust infrastructure and scalable architectures to ensure your app maintains optimal performance, even as your business grows. Whether you\'re anticipating rapid expansion or need to handle peak demands, our mobile app development services guarantee your app can scale seamlessly, delivering a consistent and exceptional user experience. '}</p>
                                <p>{activeSection === 'Cross-Platform Development' && 'At Anantam, we specialize in cross-platform mobile app development, enabling you to reach a wider audience with a single codebase. Our experienced developers utilize cutting-edge frameworks and technologies to create high-quality apps that seamlessly function on both iOS and Android devices. By leveraging cross-platform development, you can reduce development time, costs, and maintain consistency across platforms, ensuring a superior user experience for all. '}</p>
                                <p>{activeSection === 'Dedicated App Developers' && 'At Anantam, we offer dedicated app developers who are committed to delivering exceptional mobile app solutions tailored to your specific needs. Our experienced and skilled developers work exclusively on your project, ensuring personalized attention, rapid development cycles, and seamless communication. By partnering with Anantam, you gain access to a dedicated team of experts who are fully invested in your success, delivering high-quality mobile apps that exceed your expectations.'}</p>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Enhancing Efficiency' ? 'Consolidate multiple applications' : activeSection === 'Protecting Your Mobile App' ? 'Comprehensive testing' : activeSection === 'High Scalability' ? 'Future-proof design' : activeSection === 'Cross-Platform Development' ? 'Reliable' : activeSection === 'Dedicated App Developers' ? 'Deep domain expertise' : ''}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Enhancing Efficiency' ? 'Automate repetitive tasks' : activeSection === 'Protecting Your Mobile App' ? 'Data encryption' : activeSection === 'High Scalability' ? 'Modular architecture' : activeSection === 'Cross-Platform Development' ? 'Time-saving' : activeSection === 'Dedicated App Developers' ? 'Collaborative approach' : ''}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Enhancing Efficiency' ? 'Improve data accessibility' : activeSection === 'Protecting Your Mobile App' ? 'Customized security solutions' : activeSection === 'High Scalability' ? 'Enhanced performance' : activeSection === 'Cross-Platform Development' ? 'Cost-effective' : activeSection === 'Dedicated App Developers' ? 'Cost-effectiveness' : ''}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- /Service Details Section -->  */}

                {/* Mobile application development Teck track  */}
                <section className="section ">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Our Mobile app Development Tech Stack<br /></h2>
                    </div>
                    <div className="container ">
                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Frameworks</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Flutter</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>React Native</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Ionic</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Android</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>iOS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Languages</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>JavaScript</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Swift</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Kotlin</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Java</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Tools</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Visual Studio Code</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Android Studio</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>PhoneGap</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Xcode</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ScrollToTop/>
        </>
    );
}

export default ServicesDetail;