import React, { useEffect } from 'react';

function Preloader() {
  useEffect(() => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      window.addEventListener('load', () => {
        preloader.style.opacity = 0;
        setTimeout(() => {
          preloader.style.display = 'none';
        }, 600);
      });
    }
  }, []);

  return (
    <div id="preloader"></div>
  );
}

export default Preloader;