import React from "react";
import about from "../assets/img/about.png";
import about2 from "../assets/img/about-2.png";

function AboutSection(){
    return(
        <section id="about" className="about section">
        {/* <!-- Section Title --> */}
        <div className="container section-title" data-aos="fade-up">
          <h2>Who Are We?<br/></h2>
          <p>A Team Dedicated to Your Success</p>
        </div> {/* <!-- End Section Title --> */}
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              {/* <!-- <h3>At Anantam IT Solutions, we are dedicated to delivering value and excellence, and we strive to build lasting partnerships with our clients based on trust and mutual success.</h3> --> */}
              <img src={about} className="img-fluid rounded-4 mb-4" alt=""/>
              <p>At Anantam, we are a dedicated team of passionate tech professionals with extensive expertise in
                delivering innovative and high-quality IT solutions. Our goal is to help businesses enhance their
                operations, reduce costs, and increase productivity by leveraging the latest technologies and industry
                best practices. We ensure that our clients remain at the forefront of the ever-evolving tech landscape.
              </p>
              <p>We believe that every business is unique, and so are its needs. That's why we take a personalized
                approach, working closely with each client to understand their specific challenges and goals. Whether it's
                managing your IT infrastructure, optimizing workflows, or implementing new technology solutions, we
                customize our services to meet your exact requirements.</p>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="250">
              <div className="content ps-0 ps-lg-5">
                <p className="fst-italic">
                  Our comprehensive suite of services includes:
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> <span>Ensuring robust, scalable, and secure IT systems to
                      support your business operations.</span></li>
                  <li><i className="bi bi-check-circle-fill"></i> <span>Streamlining processes to enhance efficiency and
                      productivity.</span></li>
                  <li><i className="bi bi-check-circle-fill"></i> <span>Integrating cutting-edge solutions tailored to your
                      business needs.</span></li>
                </ul>
                <p>
                  At Anantam, we are committed to delivering value and excellence, helping you navigate the complexities
                  of the digital world with confidence.
                  Our team’s expertise and dedication ensure that you receive solutions that are not only innovative but
                  also aligned with your strategic objectives,
                  driving growth and success for your business.
                </p>
                <div className="position-relative mt-4">
                  <img src={about2} className="img-fluid rounded-4" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </section>
    );
}
export default AboutSection;