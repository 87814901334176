import React from "react";
import Logo from "../assets/img/logo.png"
import { Link } from "react-router-dom";

function Header(){
    return(
        <header id="header" className="header fixed-top">
        <div className="topbar d-flex align-items-center">
          <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
              <i className="bi bi-envelope d-flex align-items-center"><a
                  href="mailto:contact@example.com">hello@anantamit.com</a></i>
              <i className="bi bi-phone d-flex align-items-center ms-4"><span>+91 972727-0155</span></i>
            </div>
            <div className="social-links d-none d-md-flex align-items-center">
              <a href="https://www.linkedin.com/company/anantam-it-solutions/" target="_blank" className="linkedin"><i
                  className="bi bi-linkedin"></i></a>
              <a href="#" target="_blank" className="facebook"><i className="bi bi-facebook"></i></a>
              <a href="https://www.instagram.com/anantam_it" target="_blank" className="instagram"><i
                  className="bi bi-instagram"></i></a>
            </div>
          </div>
        </div>
        <div className="container-fluid py-3">
          <div className="container branding d-flex align-items-cente py-2">
    
            <div className="container position-relative d-flex align-items-center justify-content-between">
            <a href="/" className="logo d-flex align-items-center">
                <img src={Logo} alt="Logo"/>
            </a>
    
              <nav id="navmenu" className="navmenu">
                <ul>
                  <li><Link to="/"><a className="active">Home<br/></a></Link></li>
                  <li><a href="#services">Services</a></li>
                  <li><a href="#hireDeveloper">Hire Dedicated Developers</a></li>
                  {/* <li><a href="#blog">Blog</a></li> */}
                  {/* <li className="dropdown"><a href="#"><span>About</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                    <ul>
                      <li><a href="#">About Anantam</a></li>
                      <li><a href="#">Team</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Career</a></li>
                    </ul>
                  </li> */}
                  <li><span href = "#contact" className="ais-btn hover-hand p-2">Contact Us</span></li>
                </ul>
                <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
              </nav>
    
            </div>
    
          </div>
        </div>
    
        </header>
    );
}
export default Header;


 