import React, { useState } from "react";
import CWApp from "../assets/img/webappDev/cwapp.png";
import UIUX from "../assets/img/webappDev/uiux.png";
import webdesign from "../assets/img/web-design.png"
import mobiledesign from "../assets/img/mobile-ui-design.png";
import responsivedesigne from "../assets/img/responsivedesigne.png";
import mobileappTechAdvice from "../assets/img/mobileappDev/mobileappTechAdvice.png";
import OpenSDev from "../assets/img/webappDev/opensdev.png";
import QA from "../assets/img/webappDev/qa.png";
import FrontDev from "../assets/img/webappDev/frontdev.png";
import BackDev from "../assets/img/webappDev/backdev.png";
import ScrollToTop from "../components/ScrollToTop";


function UiUxService() {
    const [activeSection, setActiveSection] = useState('Enhanced User Engagement');
    const handleTagClick = (section) => {
        setActiveSection(section);
    };

    return (
        <>
            <div className="no-context-menu no-select">
                {/* <!-- Page Title -->  */}
                <div className="page-title dark-background">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>UI/UX and graphic designing</h1>
                                    <p className="mb-0 b-text">Innovative UI/UX Solutions That Drive Growth.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="/">Home</a></li>
                                <li className="current">UI/UX and Graphic Designing</li>
                            </ol>
                        </div>
                    </nav>
                </div>
                {/* <!-- End Page Title -->  */}

                {/* <!-- Services Section -->  */}
                <section id="services" className="services section">
                    {/* <!-- Section Title -->  */}
                    <div className="container section-title" data-aos="fade-up">
                        <h2>UI/UX and Graphic Designing Services</h2>
                    </div>
                    {/* <!-- End Section Title -->  */}

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={mobileappTechAdvice} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>UI/UX Design Consulting</h3>
                                                <p className="card-text">Get expert guidance to optimize user experiences, align business goals, and ensure effective, user-friendly UI/UX design solutions that enhance customer satisfaction and loyalty. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={UIUX} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Wireframing & Prototyping</h3>
                                                <p className="card-text">We create blueprint-like structures and interactive mock-ups that help visualize design concepts and refine functionality early in the design process for better UI/UX design outcomes. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}

                            </div>



                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">

                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={webdesign} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Web App UX/UI Design</h3>
                                                <p className="card-text">Our team crafts intuitive, visually appealing user interfaces for web apps, enhancing user engagement and usability ultimately driving conversions and positive interactions.</p>
                                                {/* <!-- <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p> -->  */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>



                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={mobiledesign} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Mobile App UI/UX Design</h3>
                                                <p className="card-text">We design seamless, intuitive interfaces for mobile apps, focusing on efficient navigation, usability, and aesthetics. Seek the best mobile app UI/UX design services.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>



                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={FrontDev} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Website Design </h3>
                                                <p className="card-text">Want to create compelling and user-friendly websites that captivate visitors? Look no further than Anantam website design services. Connect with us now!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}

                            </div>



                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={responsivedesigne} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Responsive Design</h3>
                                                <p className="card-text">Enhance usability and boost user satisfaction with our exclusive responsive design services. Get a perfect website design, delivering consistent and accessible user experiences.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- /Services Section -->  */}

                {/* <!-- Service Details Section -->  */}
                <section className="stats section">
                    <div className="container" >
                        <div className="row gy-4 align-items-center">
                            <div className="col-lg-5 section-title" style={{ paddingBottom: '0%' }}>
                                <h2>UI/UX Design Benefits<br /></h2>
                            </div>
                            <div className="col-lg-7">
                                <div className="row gy-4">
                                    <p>UI/UX design plays a crucial role in creating exceptional user experiences. By focusing on intuitive interfaces, visually appealing aesthetics, and seamless interactions, UI/UX designers can enhance user satisfaction, increase engagement, and drive conversions. A well-designed user interface and user experience can help businesses build brand loyalty, improve customer satisfaction, and ultimately achieve their marketing goals.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="service-details" className="service-details section">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="services-list">
                                    <a className={activeSection === 'Enhanced User Engagement' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Enhanced User Engagement')}>Enhanced User Engagement</a>
                                    <a className={activeSection === 'Improved User Experience' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Improved User Experience')}>Improved User Experience</a>
                                    <a className={activeSection === 'Strong Brand Identity' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Strong Brand Identity')}>Strong Brand Identity</a>
                                    <a className={activeSection === 'Increased Conversions' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Increased Conversions')}>Increased Conversions</a>
                                    <a className={activeSection === 'Time and Cost Savings' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Time and Cost Savings')}>Time and Cost Savings</a>
                                </div>
                            </div>

                            <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
                                <h3>{activeSection}</h3>
                                <p>{activeSection === 'Enhanced User Engagement' && 'Our UI/UX & Graphic Design team is dedicated to creating designs that not only look visually appealing but also actively engage users and encourage interaction. By leveraging proven design principles and innovative techniques, we strive to craft experiences that resonate with your target audience and drive positive outcomes.'}</p>
                                <p>{activeSection === 'Improved User Experience' && 'Our UI/UX & Graphic Design team is committed to creating intuitive and easy-to-use interfaces that enhance the overall user experience. We believe that a well-designed interface can significantly impact user satisfaction, engagement, and conversion rates.'}</p>
                                <p>{activeSection === 'Strong Brand Identity' && 'Our UI/UX & Graphic Design team is dedicated to helping you establish a strong and memorable brand identity that resonates with your target audience. A well-defined brand identity can help you differentiate yourself from competitors, build customer loyalty, and increase brand recognition.'}</p>
                                <p>{activeSection === 'Increased Conversions' && 'Our UI/UX & Graphic Design team is dedicated to creating designs that drive conversions and help you achieve your business objectives. By focusing on user experience, engagement, and persuasive design elements, we can help you increase conversions and maximize your return on investment'}</p>
                                <p>{activeSection === 'Time and Cost Savings' && 'Our UI/UX & Graphic Design team is committed to delivering high-quality designs efficiently and cost-effectively. We employ streamlined processes and leverage advanced tools to minimize turnaround times and maximize your return on investment.'}</p>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Enhanced User Engagement' ? 'Intuitive Navigation' : activeSection === 'Improved User Experience' ? 'Clear and Consistent Layout' : activeSection === 'Strong Brand Identity' ? 'Brand Storytelling' : activeSection === 'Increased Conversions' ? 'A/B Testing ' : activeSection === 'Time and Cost Savings' ? 'Efficient Workflows' : ''}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Enhanced User Engagement' ? 'Visually Appealing Elements' : activeSection === 'Improved User Experience' ? 'Consistent Branding' : activeSection === 'Strong Brand Identity' ? 'Consistent Branding ' : activeSection === 'Increased Conversions' ? 'Mobile Optimization ' : activeSection === 'Time and Cost Savings' ? 'Advanced Design Tools' : ''}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Enhanced User Engagement' ? 'Mobile Optimization' : activeSection === 'Improved User Experience' ? 'Accessibility' : activeSection === 'Strong Brand Identity' ? 'Brand Guidelines' : activeSection === 'Increased Conversions' ? 'Analytics and Tracking ' : activeSection === 'Time and Cost Savings' ? 'Iterative Design' : ''}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                {/* web application development Teck track  */}
                <section className="section ">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Our UI/UX Service Tech Stack<br /></h2>
                    </div>
                    <div className="container ">
                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Design Tools</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Figma</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Adobe XD</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Sketch</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>InVision Studio</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Front-End Development Tools</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>React</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Vue.js</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Angular</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Prototyping Tools</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>InVision</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>ProtoPie</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Framer</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ScrollToTop/>
        </>
    );
}

export default UiUxService;