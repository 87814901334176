import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import NICT1 from "../assets/img/portfolio/NICT/NICT-1.jpg";
import NICT2 from "../assets/img/portfolio/NICT/NICT-2.jpg";
import NICT3 from "../assets/img/portfolio/NICT/NICT-3.jpg";
import NICT4 from "../assets/img/portfolio/NICT/NICT-4.jpg";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import 'swiper/css/pagination';
import ScrollToTop from '../components/ScrollToTop';


function NictPortfolio() {

    const [data, setData] = useState([]);

    useEffect(() => {
      // Fetch JSON data from a file or API endpoint
      fetch('data.json')
        .then(response => response.json())
        .then(data => setData(data));
    }, []);

    const images = [
        // Add your image URLs here
         NICT1,NICT2,NICT3,NICT4 
         // ...
    ];

    return (
        <>
            {/* <!-- Page Title -->  */}
            <div className="page-title">
                <div className="heading">
                    <div className="container">
                        <div className="row d-flex justify-content-center text-center">
                            <div className="col-lg-8">
                                <h1>Portfolio Details</h1>
                                <p className="mb-0">A Detailed Look at Our Projects</p>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li className="current">Portfolio Details</li>
                        </ol>
                    </div>
                </nav>
            </div>
            {/* <!-- End Page Title --> */}

            {/* <!-- Portfolio Details Section --> */}
            <section id="portfolio-details" className="portfolio-details section">
                <div className="container" data-aos="fade-up">
                    <div className="portfolio-details-slider swiper init-swiper">
                        <Swiper
                            modules={[Navigation, Pagination]}
                            spaceBetween={30}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}                                 
                        >
                            {images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img src={image} alt={`Portfolio Image ${index + 1}`} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="row justify-content-between gy-4 mt-4">

                        <div className="col-lg-8" data-aos="fade-up">
                            <div className="portfolio-description">
                                {/* <h2>Technical Training Alliance</h2> */}
                                <p><b>Advanced Organizational Management Solution with Online Exams portal</b></p>

                                <h2>Client’s requirements</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The client requires the development of a website for their organization, which will provide login access to franchise firms. These franchises will use a dedicated section of the website to conduct online examinations featuring multiple-choice questions (MCQs) with time deadlines. Franchise firms will also be able to insert and manage student data. Additionally, the website will include a feature for verifying the authenticity of certifications. Users can enter a certificate ID to check if the certificate is valid. A super user will have the capability to manage details of other organizations, create new logins for new customers, and display a count of connected franchises along with detailed information.</p>

                                <h2>Key requirement</h2>
                                <ul>
                                    <li><span>User Login System</span></li>
                                    <li><span>Online Examination Module</span></li>
                                    <li><span>Student Data Management</span></li>
                                    <li><span>Certification Verification</span></li>
                                    <li><span>Super User Management</span></li>
                                    <li><span>franchise details</span></li>
                                </ul>

                                <h2>Approach</h2>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our development team began by thoroughly understanding the client’s vision. We then created a detailed roadmap to guide the project. The first step was to design and implement a secure login system for franchise firms, allowing each franchise to manage their dedicated section of the website.
                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We developed an online examination module with timed multiple-choice questions (MCQs) and integrated it with a robust student data management system. This enables franchise firms to easily insert and manage student information.To ensure certification authenticity, we implemented a verification feature that allows users to check the validity of certificates using a certificate ID.
                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Additionally, we created a comprehensive super user management system. This system allows the super user to manage other organizations’ details, create new logins for customers, and display detailed information about connected franchises.Throughout the development process, we focused on optimizing the database and ensuring seamless integration of all features. We also prioritized enhancing the website’s performance to ensure fast loading times and a smooth user experience. 
                                </p>


                                <h2>Solutions We Provided</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our team began by designing a secure login system tailored for franchise firms, ensuring each franchise could manage their dedicated section of the website seamlessly. We developed an online examination module featuring timed multiple-choice questions (MCQs) and integrated it with a robust student data management system. This allowed franchise firms to efficiently insert and manage student information.<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To address the need for certification verification, we implemented a feature that enables users to verify the authenticity of certificates using a certificate ID. This ensures quick and reliable validation of certifications.
                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We also created a comprehensive super user management system. This system empowers the super user to manage details of other organizations, create new logins for customers, and display detailed information about connected franchises.  
                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Throughout the development process, we focused on optimizing the database and ensuring seamless integration of all features. We prioritized enhancing the website’s performance, ensuring fast loading times and a smooth user experience. Our team’s meticulous approach ensured that all functionalities were robust and user-friendly, meeting the client’s specifications effectively.
                                </p>



                                <h2>Key features</h2>
                                <ul>
                                    <li><span style={{textAlign:'justify'}}><b>Secure Login System:</b> Allows franchise firms to access and manage their dedicated sections.</span></li>
                                    <li><span style={{textAlign:'justify'}}><b>Online Examination Module: </b>Supports timed MCQs and integrates with student data management.</span></li>
                                    <li><span style={{textAlign:'justify'}}><b>Student Data Management:</b> Enables franchises to insert and manage student information efficiently.</span></li>
                                    <li><span style={{textAlign:'justify'}}><b>Certification Verification: </b>Allows users to verify the authenticity of certificates using a certificate ID.</span></li>
                                    <li><span style={{textAlign:'justify'}}><b>Super User Management:</b> Empowers the super user to manage other organizations, create new logins, and track franchise details.</span></li>
                                    <li><span style={{textAlign:'justify'}}><b>Performance Optimization:</b> Ensures fast loading times and a smooth user experience through database optimization and seamless feature integration.</span></li>
                                </ul>
                                {/* <div className="testimonial-item">
                                    <p>
                                        <i className="bi bi-quote quote-icon-left"></i>
                                        <span>Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.</span>
                                        <i className="bi bi-quote quote-icon-right"></i>
                                    </p>
                                    <div>
                                        <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" />
                                        <h3>Sara Wilsson</h3>
                                        <h4>Designer</h4>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                            <div className="portfolio-info">
                                <h3>Project information</h3>
                                <ul>
                                    <li><strong>Category</strong> Web Development with desktop app</li>
                                    <li><strong>Client</strong> NICT organization</li>
                                    {/* <li><strong>Project date</strong> 01 March, 2024</li>
                                    <li><strong>Project URL</strong> <a href="#">https://thettainc.com/</a></li>
                                    <li><a href="#" className="btn-visit align-self-start">Visit Website</a></li> */}
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
            {/* <!-- /Portfolio Details Section --> */}
            <ScrollToTop />
        </>
    );
}
export default NictPortfolio;